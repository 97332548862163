import { useQuery, UseQueryResult } from "react-query";
import { filter } from "../../../store/SideFilters/RequestFilters/types";
import { IFilter, IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";
import { ITypeGraphicHorizontalBar } from "./onePageTypes";

export interface IFormatData {
  x: number[];
  y: string[];
  label: string[];
}

export interface IFormatPeriodData {
  y: number[];
  text: string[];
}

export interface IFormatDataWaterfall {
  x: string[];
  y: string[];
  measure: string[];
  label: string[];
}

interface IMKTShareContribution {
  config: {
    labels: string[];
    labelsColor: string[];
  };
  marketShareContribution: {
    waterfall: IFormatDataWaterfall;
    varMarketShare: ITypeGraphicHorizontalBar;
    msValue: IFormatData;
    varSellOut: ITypeGraphicHorizontalBar;
  };
}

export interface IParamsMKTShareContribution {
  subChannel: string;
  banner: string;
  region: string;
  uf: string;
  period: filter;
}

const requestMKTShareContribution = async (
  period: string,
  filters: IStateFilters,
  internalFilter: IFilter,
  type: string
): Promise<IMKTShareContribution> => {
  let endPoint = "";

  if (filters.filter.businessUnitId.selectedFilterId === "")
    endPoint = "one_page_market_share_contribution_bu/";
  else if (filters.filter.category.selectedFilterId === "")
    endPoint = "one_page_market_share_contribution_category/";
  else if (internalFilter.id === "")
    endPoint = "one_page_market_share_contribution_brand/";
  else endPoint = "one_page_market_share_contribution_segment/";

  const response = await api.get<IMKTShareContribution>(endPoint, {
    params: {
      period: period.toLocaleLowerCase() || "",
      businessUnit: filters.filter.businessUnitId.selectedFilterId || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      category: filters.filter.category.selectedFilterId || null,
      subCategory: internalFilter.id || null,
      is_promo: filters.promoFilter.merchandising.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      customer_sales_rep:
        filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
      type: type || null,
    },
  });

  response.data.marketShareContribution.waterfall.y =
    response.data.marketShareContribution.waterfall.y.map((legend) => {
      if (legend.length > 13) return `${legend.substring(0, 15)}.`;
      return legend;
    });

  return response.data;
};

export const useMarketShareContribution = (
  period: string,
  filters: IStateFilters,
  internalFilter: IFilter,
  type: string
): UseQueryResult<IMKTShareContribution, unknown> => {
  return useQuery(
    ["marketShareContribution", filters, period, internalFilter, type],
    () => requestMKTShareContribution(period, filters, internalFilter, type),
    {
      refetchOnWindowFocus: false,
    }
  );
};
