/* eslint-disable camelcase */
import { useQuery, UseQueryResult } from "react-query";
import { IStateFilters } from "../../../store/SideFilters/types";
import api from "../../api";

interface IHeaderData {
  industry: string;
  values: string[];
}

interface IContentData {
  category: string;
  values: string[];
}

interface IResponseFormatData {
  header: IHeaderData;
  content: IContentData[];
}

const requestPerformanceCategories = async (
  filters: IStateFilters,
  type: string
): Promise<IResponseFormatData> => {
  let endPoint = "";

  if (filters.filter.businessUnitId.selectedFilterId === "")
    endPoint = "one_page_market_share_and_performance_bu";
  else if (filters.filter.category.selectedFilterId === "")
    endPoint = "one_page_market_share_and_performance_cat";
  else if (filters.filter.brand.selectedFilterId === "")
    endPoint = "one_page_market_share_and_performance_brand";

  const response = await api.get<IResponseFormatData>(endPoint, {
    params: {
      businessUnit: filters.filter.businessUnitId.selectedFilterId || null,
      category: filters.filter.category.selectedFilterId || null,
      subChannel: filters.promoFilter.subChannel.selectedFilterId || null,
      banner: filters.promoFilter.banner.selectedFilterId || null,
      region: filters.promoFilter.region.selectedFilterId || null,
      uf: filters.promoFilter.uf.selectedFilterId || null,
      is_promo: filters.promoFilter.merchandising.selectedFilterId || null,
      director: filters.promoFilter.commercialDirector.selectedFilterId || null,
      manager: filters.promoFilter.commercialManager.selectedFilterId || null,
      customer_sales_rep: filters.promoFilter.commercialSaler.selectedFilterId || null,
      cnpj: filters.promoFilter.storeCNPJ || null,
      type: type || null,
    },
  });

  return response.data;
};

export const usePerformanceCategories = (
  filters: IStateFilters,
  type: string
): UseQueryResult<IResponseFormatData> => {
  return useQuery(
    ["performanceCategories", filters, type],
    () => requestPerformanceCategories(filters, type),
    {
      refetchOnWindowFocus: false,
    }
  );
};
